import React from 'react';

const Submitted = () => (
  <div className="s-submitted-message">
    <div className="s-submitted-message-box">
      <div className="s-submitted-icon">
        <i className="far fa-thumbs-up"></i>
      </div>
      <div className="item item-text title">
        <h2 className="text-content">
          <p>Thank You</p>
        </h2>
      </div>
      <div className="item item-text item-2 body" id="item-26545">
        <div className="text-content">
          <p>We've received your submission, and we'll be in touch soon!</p>
        </div>
      </div>
    </div>
  </div>
);

export default Submitted;
