import 'core-js/stable';
import fetch from 'isomorphic-fetch';

const getParams = (method = 'GET') => ({ method });

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

const doRequest = (method, url, data) =>
  new Promise((resolve, reject) => {
    let params = getParams(method);

    // Use the FormData object cuz this won't work => JSON.stringify(data)
    if (data) params.body = data;

    fetch(url, params)
      .then(checkStatus)
      .then(resp =>
        resp.json().then(
          json => json,
          error => null
        )
      )
      .then(d => resolve(d))
      .catch(ex => {
        console.warn('API error (FROM api.js)');
        console.trace();
        reject(ex);
      });
  });

const doGet = url => doRequest('GET', url);
const doPost = (url, data) => doRequest('POST', url, data);
const doPatch = (url, data) => doRequest('PATCH', url, data);
const doPut = (url, data) => doRequest('PUT', url, data);
const doDelete = (url, data) => doRequest('DELETE', url, data);

let host = 'orbitalsites.com';
let protocol = 'https';

const API = {
  setHostAndProtocol: (incoming_host, incoming_protocol) => {
    host = incoming_host;
    protocol = incoming_protocol;
  },
  getPodcast: (protocol, host, uuid) =>
    doGet(`${protocol}://api.${host}/podcast/${uuid}.json`),
  getForm: id => doGet(`${protocol}://api.${host}/form/${id}.json`),
  submitForm: (id, data) =>
    doPost(`${protocol}://api.${host}/form/${id}`, data),
  getAnnouncements: (protocol, host, site) =>
    doGet(`${protocol}://api.${host}/announcements/${site}.json`),
  sendAnnouncementEvent: (protocol, host, announcement, name) =>
    doGet(`${protocol}://api.${host}/announcement/${announcement}/e/${name}`),
};

export default API;
