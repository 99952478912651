import React from 'react';
import Loading from './Loading';

const Button = ({ loading, submitted, submittedText }) => (
  <div className="s-form-button item-button">
    <button
      type="submit"
      className="button"
      disabled={submitted ? true : false}
    >
      <Loading loading={loading}>
        {submitted ? submittedText || 'Submitted' : 'Submit'}
      </Loading>
    </button>
  </div>
);

export default Button;
