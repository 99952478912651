import React from 'react';
import Button from '../components/Button';
import Submitted from '../components/Submitted';

const Contact = ({ id, loading, formSubmit, submitted }) => (
  <form onSubmit={formSubmit} noValidate>
    <fieldset>
      <Submitted />
      <input type="hidden" value={id} name="form_id" />
      <div className="s-form-input">
        <label htmlFor={`${id}-name`}>Name</label>
        <input
          id={`${id}-name`}
          name="name"
          type="text"
          placeholder="Your name here"
          required
        />
      </div>

      <div className="s-form-input">
        <label htmlFor={`${id}-email`}>Email</label>
        <input
          id={`${id}-email`}
          name="email"
          type="email"
          placeholder="Your e-mail here"
          required
        />
      </div>

      <div className="s-form-input">
        <label htmlFor={`${id}-phone`}>Phone</label>
        <input
          id={`${id}-phone`}
          name="phone"
          type="tel"
          placeholder="888 888 8888"
          pattern="[0-9]{3} ?[0-9]{3} ?[0-9]{4}"
          maxLength="12"
        />
      </div>

      <div className="s-form-input">
        <label htmlFor={`${id}-note`}>Comment</label>
        <textarea
          id={`${id}-note`}
          name="note"
          type="note"
          placeholder="Your comments"
        ></textarea>
      </div>

      <label className="inputs" htmlFor={`${id}-address`}></label>
      <input
        className="inputs"
        autoComplete="new-password"
        type="text"
        id={`${id}-address`}
        name="address"
        placeholder="Your address here"
      />
    </fieldset>
    <Button loading={loading} submitted={submitted} />
  </form>
);

export default Contact;
