import React from 'react';

const HEAD_ID = 'announce-top-bar';

class TopBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);
    this.trackClick = this.trackClick.bind(this);
  }

  componentDidMount() {
    this.setHeightCssVar();
    $(window).on('window:resize', this.onResize);
    window.g_initializePageScrolledClass();
  }

  componentDidUpdate() {
    this.setHeightCssVar();
  }

  componentWillUnmount() {
    $(window).off('window:resize', this.onResize);
  }

  onResize() {
    this.setHeightCssVar();
  }

  setHeightCssVar() {
    let topBarCss = `:root { --a-top-bar-height: ${this.el.offsetHeight}px; }`;

    if ($(`head #${HEAD_ID}`).length) {
      $(`head #${HEAD_ID}`).html(topBarCss);
    } else {
      $('head').append(
        `<style type="text/css" id="${HEAD_ID}">${topBarCss}</style>`
      );
    }
  }

  trackClick() {
    this.props.sendAnnouncementEvent(this.props.uuid, 'click');
  }

  getHref() {
    try {
      return this.props.settings.link.href;
    } catch (e) {
      return '';
    }
  }

  render() {
    return (
      <a
        ref={c => (this.el = c)}
        className="a-top-bar"
        href={this.getHref()}
        onClick={this.trackClick}
      >
        <span className="text-content a-top-bar-content">
          {this.props.body}
        </span>
      </a>
    );
  }
}

class TopBarWrapper extends React.PureComponent {
  render() {
    return this.props.announcements.length > 0 ? (
      <TopBar
        {...this.props.announcements[0]}
        sendAnnouncementEvent={this.props.sendAnnouncementEvent}
      />
    ) : null;
  }
}

export default TopBarWrapper;
