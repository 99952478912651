import React from 'react';
import Podcast from './Podcast';
import Video from './Video';

const Player = props =>
  props.item.item_type === 'video' ? (
    <Video {...props} />
  ) : (
    <Podcast {...props} />
  );

export default Player;
