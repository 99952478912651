import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import Plyr from 'plyr-react';

import Details from './Details';
import CoverPhoto from './CoverPhoto';

const AudioPlayer = forwardRef(({ item }, ref) => {
  const plyrRef = useRef();
  useImperativeHandle(ref, () => ({
    play: () => setTimeout(plyrRef.current.plyr.play, [0])
  }))

  return (
    <div className="p-audio-player">
      <div className="p-inner-container">
        <CoverPhoto item={item} />
        <Details body item={item} />
      </div>
      <Plyr
        ref={plyrRef}
        source={{
          type: 'audio',
          sources: [
            {
              src: item.media_url,
            },
          ],
        }}
      />
    </div>
  )
})

export default AudioPlayer;
