import React from 'react';

class CoverPhoto extends React.PureComponent {
  render() {
    if (!this.props.item) return null;
    const item = this.props.item;

    return (
      <div className="p-cover-photo">
        <div className="photo-container center">
          <div style={{ paddingTop: '56.25%' }} />
          <a className="photo-holder">
            <img src={item.display_image_url} />
          </a>
        </div>
        {this.props.play && (
          <svg className="p-play-overlay" role="presentation" focusable="false">
            <use xlinkHref="#plyr-play"></use>
          </svg>
        )}
      </div>
    );
  }
}

export default CoverPhoto;
