import React from 'react';
import _i from 'immutable-functions';

const Reaction = props => (
  <li
    className={[
      'a-chat-bubble-reaction',
      props.isActive ? 'a-chat-bubble-reaction-active' : '',
    ].join(' ')}
    onClick={!props.isActive ? props.onClick : null}
  >
    <span className="a-chat-bubble-reaction-icon">{props.reaction}</span>
    <span className="a-chat-bubble-reaction-count">
      {props.count > 0 ? props.count : ''}
    </span>
  </li>
);

const Reactions = props => (
  <ul className="a-chat-bubble-reactions">
    {Object.keys(props.reactionData).map(r => (
      <Reaction
        key={r}
        reaction={r}
        count={props.reactionData[r]}
        isActive={(props.localReactions.events || []).indexOf(r) > -1}
        onClick={props.handleReactionPress(r)}
      />
    ))}
  </ul>
);

class ChatBubble extends React.PureComponent {
  state = {
    isMounted: window.__a_chat_bubble_mounted || false,
    isShown: window.__a_chat_bubble_shown || false,
  };

  componentDidMount() {
    if (!this.state.isMounted)
      this._showDelay = _.delay(this.handleInitialRender, 750);
  }

  componentWillUnmount() {
    clearTimeout(this._showDelay);
  }

  getReactionData() {
    return this.props.reaction_counts;
  }

  handleReactionPress = reaction => () => {
    this.props.sendAnnouncementEvent(this.props.uuid, reaction);
  };

  handleInitialRender = () => {
    window.__a_chat_bubble_mounted = true;
    window.__a_chat_bubble_shown = true;
    this.setState({ isShown: true });
  };

  handleIconPress = () => {
    window.__a_chat_bubble_shown = !this.state.isShown;
    this.setState({ isShown: !this.state.isShown });
  };

  render() {
    return (
      <div
        className={[
          'a-chat-bubble',
          this.state.isShown ? 'a-chat-bubble-show' : '',
        ].join(' ')}
      >
        <div className="a-chat-bubble-box">
          {this.props.display_name && (
            <div className="a-chat-bubble-title">{this.props.display_name}</div>
          )}
          <div className="a-chat-bubble-body">{this.props.body}</div>
          <Reactions
            handleReactionPress={this.handleReactionPress}
            reactionData={this.getReactionData()}
            {...this.props}
          />
        </div>
        <button className="a-chat-bubble-icon" onClick={this.handleIconPress}>
          <span className="a-chat-bubble-graphic">
            <i className="far fa-comment"></i>
          </span>
          <span className="a-chat-bubble-indicator"></span>
        </button>
      </div>
    );
  }
}

class ChatBubbleWrapper extends React.PureComponent {
  render() {
    return this.props.announcements.length > 0 ? (
      <ChatBubble
        {...this.props.announcements[0]}
        localReactions={_i.findByProp(
          this.props.localReactions,
          'uuid',
          this.props.announcements[0].uuid
        )}
        sendAnnouncementEvent={this.props.sendAnnouncementEvent}
      />
    ) : null;
  }
}

export default ChatBubbleWrapper;
