import React from 'react';

const Loading = props => (
  <div
    className={`s-loading-container ${props.loading ? 's-loading' : ''} ${
      props.standalone ? 'stand-alone' : ''
    } ${props.inverted ? 'inverted' : ''} ${props.left ? 'left' : ''}`}
  >
    {props.loading && (
      <div className="s-loading-spinner">
        <div>Loading</div>
      </div>
    )}
    <span className="s-loading-default-text">{props.children}</span>
  </div>
);

export default Loading;
