import React from 'react';
import Button from '../components/Button';
import Submitted from '../components/Submitted';

const Email = ({ id, loading, formSubmit, submitted }) => (
  <form onSubmit={formSubmit} noValidate>
    <fieldset>
      <input type="hidden" value={id} name="form_id" />
      <div className="s-form-input">
        <input
          id={`${id}-email`}
          name="email"
          type="email"
          placeholder="Enter your e-mail"
          required
        />
      </div>
      <label className="inputs" htmlFor={`${id}-name`}></label>
      <input
        className="inputs"
        autoComplete="off"
        type="text"
        id={`${id}-name`}
        name="name"
        placeholder="Your name here"
      />
    </fieldset>
    <Button loading={loading} submitted={submitted} submittedText="Thank You" />
  </form>
);

export default Email;
