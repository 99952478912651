import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';

const BigPlayer = forwardRef(({ item }, ref) => {
  const plyrRef = useRef();
  useImperativeHandle(ref, () => ({
    play: plyrRef.current.play
  }))

  const PlayerComponent = item.enclosure_type?.startsWith('video')
    ? VideoPlayer
    : AudioPlayer

  return (
    <PlayerComponent ref={plyrRef} item={item} />
  )
});

export default BigPlayer;
