import React from 'react';
import moment from 'moment';

class Details extends React.PureComponent {
  getSpeaker() {
    let speaker = this.props.item.speaker || this.props.item.author;
    if (speaker) return `  -  ${speaker}`;
    return '';
  }

  getSeries() {
    let series = this.props.item.series;
    if (series) return `  -  ${series}`;
    return '';
  }

  render() {
    if (!this.props.item) return null;
    const item = this.props.item;
    return (
      <div className="p-details">
        <div className="p-title">{item.title}</div>
        <div className="p-subtitle">
          {moment(item.published_at).format('MMM D, YYYY')}
          {this.getSpeaker()}
          {this.getSeries()}
        </div>
        {this.props.body && <div className="p-body">{item.description}</div>}
      </div>
    );
  }
}

export default Details;
