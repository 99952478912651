import React from 'react';

const YouTubeVideo = props => (
  <iframe
    src={`https://www.youtube.com/embed/${props.id}`}
    width="560"
    height="315"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

const VimeoVideo = props => (
  <iframe
    src={`https://player.vimeo.com/video/${props.id}?portrait=0`}
    width="640"
    height="360"
    frameBorder="0"
    allowFullScreen
  />
);

class Video extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      videoType: null,
      videoId: null,
    };

    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.setInitialVideoState();
    $(`#item-${this.props.item.id}`).on('updateVideo', this.updateState);
  }

  componentWillUnmount() {
    $(`#item-${this.props.item.id}`).off('updateVideo', this.updateState);
  }

  setInitialVideoState() {
    if (!this.props.item.settings) return;
    if (!this.props.item.settings.video) return;

    this.setState({ ...this.parseVideoUrl(this.props.item.settings.video) });
  }

  updateState(e, video) {
    if (
      video &&
      (video.indexOf('youtube.com') > -1 || video.indexOf('vimeo.com') > -1)
    ) {
      this.setState({ ...this.parseVideoUrl(video) });
    } else {
      this.setState({ videoType: null, videoId: null });
    }
  }

  getYouTubeId(video) {
    let query = video.split('?')[1];
    let vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == 'v') {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  getVimeoId(video) {
    let query = video;
    let afterCom = query.split('com/')[1];
    let id = afterCom.split('&')[0];
    return id;
  }

  parseVideoUrl(video) {
    if (video.indexOf('vimeo.com') > -1) {
      return {
        videoType: 'vimeo',
        videoId: this.getVimeoId(video),
      };
    } else {
      return {
        videoType: 'youtube',
        videoId: this.getYouTubeId(video),
      };
    }
  }

  getType() {
    if (this.props.item.settings.video.indexOf('vimeo.com') > -1) {
      return 'vimeo';
    } else {
      return 'youtube';
    }
  }

  render() {
    return (
      <div className="p-video-container">
        {this.state.videoType === 'vimeo' ? (
          <VimeoVideo id={this.state.videoId} />
        ) : (
          <YouTubeVideo id={this.state.videoId} />
        )}
      </div>
    );
  }
}

export default Video;
