import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import Plyr from 'plyr-react';

import Details from './Details';

const VideoPlayer = forwardRef(({ item }, ref) => {
  const plyrRef = useRef();
  useImperativeHandle(ref, () => ({
    play: () => setTimeout(plyrRef.current?.plyr?.play, [0])
  }))

  return (
    <div className="p-video-player">
      <Plyr
        ref={plyrRef}
        source={{
          type: 'video',
          sources: [
            {
              src: item.media_url,
            },
          ],
          poster: item.image,
        }}
      />
      <Details body item={item} />
    </div>
  )
})

export default VideoPlayer;
