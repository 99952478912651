import React from 'react';
import ReactDOM from 'react-dom';
import API from '../api';
import _i from 'immutable-functions';

import TopBar from './TopBar';
import ChatBubble from './ChatBubble';

const TopBarContainer = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('a-top-bar-container')
  );
};

const ChatBubbleContainer = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('a-chat-bubble-container')
  );
};

class Announce extends React.PureComponent {
  state = {
    topBars: (window.__a_announcements_state || {}).topBars || [],
    chatBubbles: (window.__a_announcements_state || {}).chatBubbles || [],
    lastFetch: (window.__a_announcements_state || {}).lastFetch || null,
    localReactions: JSON.parse(
      window.localStorage.getItem('announcement_reactions') || '[]'
    ),
  };

  constructor(props) {
    super(props);
    this.sendAnnouncementEvent = this.sendAnnouncementEvent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (new Date() - this.state.lastFetch > 300000) {
      this.getAnnouncements();
    }
  }

  componentWillUnmount() {
    window.__a_announcements_state = this.state;

    this._isMounted = false;
  }

  getAnnouncements() {
    const { protocol, host, site } = this.props;
    API.getAnnouncements(protocol, host, site).then(
      (announcements) => {
        console.log('Announce', this._isMounted, announcements);

        if (this._isMounted)
          this.setState({
            topBars: (announcements || []).filter(
              (a) => a.announcement_type === 'bar'
            ),
            chatBubbles: (announcements || []).filter(
              (a) => a.announcement_type === 'bubble'
            ),
            lastFetch: new Date(),
          });
      },
      (e) => console.log('API Error', e)
    );
  }

  sendAnnouncementEvent(uuid, name) {
    const { protocol, host, site } = this.props;
    API.sendAnnouncementEvent(protocol, host, uuid, name);
    this.addEventToState(uuid, name);
  }

  addEventToState(uuid, name) {
    const { topBars, chatBubbles, localReactions } = this.state;

    let _state = {};
    let aType = 'topBars';
    let announcement = _i.findByProp(topBars, 'uuid', uuid);

    if (!announcement) {
      aType = 'chatBubbles';
      announcement = _i.findByProp(chatBubbles, 'uuid', uuid);
      let localAnnouncement = _i.findByProp(localReactions, 'uuid', uuid);

      if ((localAnnouncement.events || []).indexOf(name) < 0) {
        let localEvents = [...(localAnnouncement.events || []), name];
        _state['localReactions'] = _i.updateByProp(
          localReactions,
          'uuid',
          uuid,
          { uuid, events: localEvents }
        );
        window.localStorage.setItem(
          'announcement_reactions',
          JSON.stringify(_state['localReactions'])
        );
      }
    }

    let reaction_counts = { ...announcement.reaction_counts };
    if (Object.keys(reaction_counts || {}).indexOf(name) > -1)
      reaction_counts[name]++;
    _state[aType] = _i.updateByProp(this.state[aType], 'uuid', uuid, {
      reaction_counts,
    });

    this.setState(_state);
  }

  render() {
    return (
      <div className="a-announcements">
        <TopBarContainer>
          <TopBar
            announcements={this.state.topBars}
            sendAnnouncementEvent={this.sendAnnouncementEvent}
            localReactions={this.state.localReactions}
          />
        </TopBarContainer>
        <ChatBubbleContainer>
          <ChatBubble
            announcements={this.state.chatBubbles}
            sendAnnouncementEvent={this.sendAnnouncementEvent}
            localReactions={this.state.localReactions}
          />
        </ChatBubbleContainer>
      </div>
    );
  }
}

export default Announce;
