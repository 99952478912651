import React from 'react';

import Details from './Details';
import CoverPhoto from './CoverPhoto';

class ListItem extends React.PureComponent {
  render() {
    if (!this.props.item) return null;
    const item = this.props.item;
    return (
      <div className="p-list-item" onClick={() => this.props.onPress(item.id)}>
        <CoverPhoto play item={this.props.item} />
        <div className="p-right-col">
          <Details item={item} />
        </div>
      </div>
    );
  }
}

export default ListItem;
